import React from 'react';
import PlayersListContent from '../Organisms/PlayerListContent/PlayerListContent';

const Players = () => {
    return (
        <>
            <PlayersListContent />
        </>
    );
};

export default Players;
